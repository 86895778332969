
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing } from 'client-website-ts-library/types/Listing';

@Component
export default class ListingCard extends Vue {
  @Prop()
  public readonly listing!: Listing;

  @Prop({ default: false })
  public showInspectionTime!: boolean;

  get isNew(): boolean {
    const newThreshold = new Date().getTime() - 86400 * 7 * 1000;

    return new Date(this.listing.CreateDate).getTime() >= newThreshold;
  }

  get openHomeRelative(): string {
    const nextOpenHome = this.listing.InspectionTimes[0].StartDateInfo;

    if (nextOpenHome.IsToday) return 'Today';
    if (nextOpenHome.IsTomorrow) return 'Tomorrow';

    return '';
  }

  get inspectionTime(): string {
    if (this.listing.InspectionTimes.length === 0) return '';

    return `${this.listing.InspectionTimes[0].StartDateInfo.Time.LongName} - ${this.listing.InspectionTimes[0].EndDateInfo.Time.LongName}`;
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber}/${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }

  get formattedDateAvailable(): string {
    if (!this.listing!.DateAvailable) return 'now';

    const now = new Date();
    const dateAvailable = new Date(this.listing!.DateAvailable);

    if (dateAvailable <= now) return 'Now';

    return `${dateAvailable.getDate()}/${dateAvailable.getMonth() + 1}/${dateAvailable
      .getFullYear()
      .toString()
      .substr(2, 2)}`;
  }

  public get dayOfMonthSuffix(): string {
    const dayOfMoth = this.listing.Auction.Date.DayOfMonth;

    if (dayOfMoth > 3 && dayOfMoth < 21) return `${dayOfMoth}th`;
    switch (dayOfMoth % 10) {
      case 1:
        return `${dayOfMoth}st`;
      case 2:
        return `${dayOfMoth}nd`;
      case 3:
        return `${dayOfMoth}rd`;
      default:
        return `${dayOfMoth}th`;
    }
  }
}
