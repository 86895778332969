



















import { Component, Vue, Prop } from 'vue-property-decorator';

import { ImageSourceSet } from 'client-website-ts-library/types';

@Component
export default class SideBySide extends Vue {
  @Prop()
  public readonly image!: string | ImageSourceSet;

  @Prop({ default: '50%' })
  public readonly imageWidth!: string;

  @Prop({ default: '50%' })
  public readonly contentWidth!: string;

  @Prop()
  public readonly reversed!: boolean;

  @Prop()
  public readonly background!: string;

  @Prop()
  public readonly border!: string;

  public isVisible = false;

  public visibilityCheckTimeout = 0;

  public delay = 0;

  public eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

  get getReversed(): string {
    return this.reversed ? '-1' : '1';
  }

  mounted() {
    this.setupListener();
  }

  destroyListener(): void {
    document.removeEventListener('scroll', this.checkPos, this.eventListenerOpts);
  }

  beforeDestroy() {
    this.destroyListener();
  }

  setupListener(): void {
    document.addEventListener('scroll', this.checkPos, this.eventListenerOpts);

    requestAnimationFrame(() => {
      this.checkPos();
    });
  }

  checkPos(): void {
    clearTimeout(this.visibilityCheckTimeout);

    this.visibilityCheckTimeout = setTimeout(() => {
      const el = this.$el;

      const rect = el.getBoundingClientRect();

      this.isVisible = rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) - 250;

      if (this.isVisible) {
        this.destroyListener();
      }
    }, this.delay);
  }
}
