





















































import { Component, Vue } from 'vue-property-decorator';
import Cycler from '@/components/Cycler/Cycler.vue';
import CyclerItem from '@/components/Cycler/CyclerItem.vue';

@Component({
  components: {
    CyclerItem,
    Cycler,
  },
})
export default class Hero extends Vue {
  public heroCyclerItems: any[] = [{
    imageSrc: '/assets/images/photos/why-choose-us-to-manage-1.png',
    badgeImageSrc1: '/assets/images/city-winner-badge-leasing.png',
    badgeLink1: 'https://www.ratemyagent.com.au/real-estate-agency/freemans-residential-ah768/leasing/overview',
    badgeImageSrc2: '/assets/images/free-trial-badge.png',
    badgeLink2: '/90-day-free-trial',
    title: 'PROPERTY MANAGEMENT',
    tagline: 'BUY RENTALS. BUILD WEALTH. STRESS FREE.',
    actions: [{ label: 'TENANT INFO', href: '/tenant-resources' }, { label: 'LANDLORD INFO', href: '/why-choose-us-manage' }],
    wrapperClass: 'green',
  },
  {
    imageSrc: '/assets/images/sales.jpg',
    badgeImageSrc1: '/assets/images/city-winner-badge-sales.png',
    badgeLink1: 'https://www.ratemyagent.com.au/real-estate-agency/freemans-residential-ah768/sales/overview',
    title: 'PROPERTY SALES',
    tagline: 'BUY. SELL. CONFIDENCE.',
    actions: [{ label: 'BUYER INFO', href: '/investors-support' }, { label: 'SELLER INFO', href: '/why-choose-us-sales' }],
    wrapperClass: 'green',
  },
  {
    imageSrc: '/assets/images/photos/palm-trees.jpg',
    badgeImageSrc1: '/assets/images/city-winner-badge-leasing.png',
    badgeLink1: 'https://www.ratemyagent.com.au/real-estate-agency/freemans-residential-ah768/leasing/overview',
    title: 'HOLIDAY RENTALS',
    tagline: 'RELIABLE. SECURE. 7 DAYS A WEEK.',
    actions: [{ label: 'VIEW LISTINGS', href: 'https://freemans.holidayfuture.com/' }, { label: 'LANDLORD INFO', href: '/why-choose-us-manage' }],
    wrapperClass: 'green',
  },
  ];

  public currentSlide = this.heroCyclerItems[0];

  handleUpdate(slide: any) {
    this.currentSlide = this.heroCyclerItems[slide.index];
  }
}
