















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Blog } from 'client-website-ts-library/types';

@Component
export default class BlogCard extends Vue {
  @Prop()
  public readonly blog!: Blog;
}
