















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FlipCard extends Vue {
  @Prop({ required: true })
  public imagePath!: string;

  @Prop({ required: true })
  public title!: string;

  @Prop({ required: true })
  public body!: string;
}
